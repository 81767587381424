import React from 'react'
import { Stack, Box } from '@mui/material'
import RoundButton from '../shared/RoundButton'
// import { ReactComponent as Logo } from '../../assets/ChobaniLogo.svg'
import { ReactComponent as Logo } from '../../assets/cho-lc-logo.svg'
import { useAppState } from '../../contexts/State'
import MaxWidth from '../shared/MaxWidth'

export default function PLPHeader() {
    const state = useAppState()

    return (
        <Box
            sx={{
                borderBottom: '1px solid',
                borderBottomColor: 'primary.100',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                backgroundColor: 'white',
                zIndex: 10,
            }}
        >
            <MaxWidth>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        py: 2,
                    }}
                >
                    <Box
                        sx={{
                            width: {
                                xs: 110,
                                md: 140,
                            },
                        }}
                    >
                        <Logo
                            width="80%"
                            height="80%"
                        />
                    </Box>
                    <Stack
                        direction="row"
                        spacing={1}
                    >
                        {state.plpMode === 'display' && (
                            <RoundButton
                                variant="contained"
                                color="secondary"
                                onClick={state.setSelectMode}
                            >
                                Select
                            </RoundButton>
                        )}
                        {state.plpMode === 'select' && (
                            <RoundButton
                                variant="outlined"
                                color="primary"
                                onClick={state.setDisplayMode}
                            >
                                Cancel
                            </RoundButton>
                        )}
                        <RoundButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                state.setSelectMode()
                                state.selectAll()
                            }}
                        >
                            Select All
                        </RoundButton>
                    </Stack>
                </Stack>
            </MaxWidth>
        </Box>
    )
}
